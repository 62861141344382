<template>
  <div class="bg-blue-50">
    <!-- <img :src="(`/img/${lang}.png`)"> -->

    <div class="container mx-auto max-w-7xl">
    <!--
      <select
        v-model="selectedLanguage"
        class="w-16 px-3 m-2 text-black bg-transparent rounded-md"
      >
        <option
          v-for="(lang, i) in $i18n.availableLocales"
          class=""
          :key="`Lang${i}`"
          :value="lang"
        >
          <span>{{ lang }}</span>
        </option>
      </select>
      -->
      <div class="flex flex-col items-center h-[432px] justify-center p-8" style="background-image:linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/img/banner.jpg');background-position:center">
        <h1
          class="mt-5 text-4xl font-bold text-center text-gray-100"
          v-text="$t('landingpageHeader')"
        ></h1>
        <p class="mt-4 text-md  text-center text-gray-200 md:w-96">
          {{ $t("landingpageSubHeader") }}
        </p>
        <!--
        <p>
          <a
              :style="{ 'background-color': settings.colors.primaryColor }"
              :href="`${this.$store.state.baseUrl}register/vendor`"
              target="__blank"
              class="flex items-center justify-center h-10 px-2 py-2 mt-5 mb-5 text-xs font-light text-center text-white rounded-full w-30 md:w-44 md:text-base md:h-14"
            >
              {{ $t("landingpageSellerHeader") }}
            </a>
        </p>
        -->
        <!--
        <div
          class="flex items-center justify-between p-1 mt-4 bg-white rounded-full md:w-5/12"
          v-if="settings"
        >
          <input
            class="w-full p-2 border-none rounded-full placeholder:font-light placeholder:text-sm"
            v-model="search"
            type="text"
            placeholder="search a product or vendor"
          />
          <div
            @click="getSearchResult"
            class="flex items-center p-2 rounded-full cursor-pointer"
            :style="{ 'background-color': settings.colors.primaryColor }"
          >
            <img src="/img/icons/search.svg" class="w-6" alt="" />
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
export default {
  name: "HomeBanner",
  components: {},
  data() {
    return {
      search: null,
      base_url: this.$store.state.baseUrl,
      selectedLanguage: this.$i18n.locale, // Initialize with the current i18n locale
      availableLocales: ["en", "es", "ar"], // Your available languages
      settings: null,
    };
  },
  watch: {
    selectedLanguage(newLang) {
      // Store the selected language in browser storage
      localStorage.setItem("selectedLanguage", newLang);
      console.log(newLang)
      // Set the i18n locale to the selected language
      this.$i18n.locale = newLang;
    },
  },
  mounted() {
    axios
      .get(this.base_url + "api/app/settings")
      .then((response) => {
        this.settings = response.data;
      })
      .catch((error) => console.log(error));
  },

  methods: {
    getSearchResult() {
      router.push({ name: "DefaultSearch", params: { slug: this.search } });
    },
  },
};
</script>
